<template>
<div class="padding-20">
    <div class="flex-row align-items-center margin-bottom-20 font-size-18"><i class="el-icon-arrow-left font-size-20 margin-right-5 link"  @click="$router.go(-1)"></i>批量配置权限</div>
    <div class="flex-row">
        <div class="column leftlist margin-right-20">
            <div>账号列表</div>
            <div class="margin-top-20">
                <el-input
                    placeholder="请选择输入您想搜索的姓名、账号、部门、岗位"
                    suffix-icon="el-icon-search"
                    size="mini"
                    @change="searchuser"
                    v-model="userkey">
                </el-input>
            </div>
            <div  class="margin-top-20 tablescrool">
                <el-table
                    :data="userList"
                    height="calc(100vh - 265px)"
                    :row-class-name="setrowstyle"
                    empty-text="暂无未配置权限的账号"
                    @row-click="setrowselect"
                    style="width: 100%;overflow-y:auto;">
                    <el-table-column
                        prop="userName"
                        label="姓名"
                        width="80"
                       >
                    </el-table-column>
                    <el-table-column
                        prop="loginName"
                        label="账户"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="orgName"
                        label="部门"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="jobName"
                        label="岗位"
                        >
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="column rightmain">
            <powercomponens :userlist="checkeduserlist" :islist="true"></powercomponens>
        </div>
    </div>
   
</div>
</template>
<script>
import powercomponens from './powercomponens.vue';

export default {
  components: { powercomponens },
    name:"editpowerforlist",
    data() {
       return{
            userList:[],
            userListall:[],
            userkey:'',
          
       } 
    },
    methods:{
        //设置选中颜色
        setrowstyle(val){
            //console.log(val.row);
            if(val.row.ischeck){
               // console.log(val.row)
                return 'current-row';
            }else{
                return "";
            }
        },
        setrowselect(row){
            
            this.userList[this.userList.findIndex(o=>o.userId==row.userId)].ischeck=!row.ischeck

        },
        getList(){
            this.userList=[];
            this.userListall=[];
            this.$instance.get('/manage/getAccountList',{params:{isPower:false}}).then(res=>{
                if(res.data.code==0){
                    this.userList=res.data.info.filter(j=>!j.includeAdmin).map(o=>{//过滤掉管理员用户
                        return {
                            ischeck:false,
                            ...o
                        }
                    });
                    this.userListall=this.userList;
                }
            })
        },
        searchuser(val){
            if(val.trim().length>0){
                this.userList=this.userListall.filter(o=>{
                    return (o.userName && o.userName.indexOf(val.trim())>=0 )|| (o.loginName && o.loginName.indexOf(val.trim())>=0 ) || (o.orgName && o.orgName.indexOf(val.trim())>=0 ) || (o.jobName&&o.jobName.indexOf(val.trim())>=0) 
                })
            }else{
                this.userList=this.userListall;
            }
           // this.getList()
        }
    },
    computed:{
        checkeduserlist(){
            return this.userList.filter(o=>o.ischeck).map(j=>{
                return j.userId
            })
        }
    },
    mounted(){
       this.getList();
    }
}
</script>
<style lang="scss" >
.leftlist{
    box-sizing: border-box;
    width: 460px;
    margin-bottom: 0;
}
.tablescrool .el-table__body-wrapper{
    overflow-y: auto;
    tr{
        cursor: pointer;
    }
}
.rightmain{
    width: calc(100% - 300px);
    margin-bottom: 0;
}
</style>
